<template>
  <v-container :class="clienteComponent ? 'px-3' : ''">
    <v-card :flat="clienteComponent ? true : false">
      <v-card-title
        v-if="!clienteComponent"
        :class="
          clienteComponent
            ? ' text-h5 font-weight-bold pa-3'
            : 'accent text-h5 font-weight-bold pa-3'
        "
      >
        <v-icon color="primary" left>mdi-toolbox-outline</v-icon>
        Aniversários
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider v-if="!clienteComponent"></v-divider>
      <v-card-text>
        <v-card-title>
          <v-text-field
            style="max-width: 200px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            clearable
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            style="max-width: 200px"
            v-model="selectedCliente"
            :items="clientes"
            :loading="loadingCliente"
            :search-input.sync="searchCliente"
            clearable
            @input="buscaAniversario(selectedCliente, dataCalendario + '-01')"
            dense
            solo
            flat
            hide-details
            hide-no-data
            outlined
            class="mx-6 my-0"
            item-text="nome_fantasia"
            item-value="id"
            label="Clientes"
            prepend-inner-icon="mdi-account-group"
            return-object
          >
          </v-autocomplete>
          <v-card
            outlined
            class="d-flex mr-6 align-center"
            height="40px"
            style="overflow: hidden"
          >
            <v-btn
              @click="subMonth"
              color="white"
              tile
              depressed
              height="40px"
              class="px-1"
              min-width="40px"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-divider vertical class="ma-0"></v-divider>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :return-value.sync="dataCalendario"
              transition="slide-x-reverse-transition"
              slide-y-transition
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text>
                  {{ mostraMes }}
                  <v-icon right>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="dataCalendario"
                type="month"
                color="blue"
                no-title
                scrollable
                @change="$refs.menu.save(dataCalendario)"
              >
                <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(search)">
              OK
            </v-btn> -->
              </v-date-picker>
            </v-menu>
            <v-divider vertical class="ma-0"></v-divider>
            <v-btn
              @click="addMonth"
              color="white"
              tile
              depressed
              height="40px"
              class="px-1"
              min-width="40px"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
          <v-btn
            color="primary"
            class="white--text"
            :disabled="!listaAniversarios.length"
            @click="gerarpdf"
          >
            <!-- <v-icon left>mdi-file-pdf-box</v-icon> -->
            PDF
          </v-btn>
          <!-- <v-btn
            @click="addaniversario"
            align="center"
            color="secondary"
            class="white--text "
            :small="clienteComponent ? true : false"
          >
            adicionar
          </v-btn> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaAniversarios"
            :single-expand="true"
            item-key="id_aniversario"
            :items-per-page="10"
            class="data-tables data-tables__row-click"
            @click:row="editaniversario"
          >
            <template v-slot:item.data_aniversario="{ item }">
              {{ item.data_aniversario | dateFormat("dd/MM/yyyy") }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>

      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="ANIVERSÁRIOS EQUIPES DE CLIENTES"
      />
    </v-card>
  </v-container>
</template>

<script>
import { fetchAniversariosEquipeClientes } from "@/api/gestor/relatorios/atividades_clientes";
const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "@/helpers/pdf/geral.js");
import {
  parseISO,
  format,
  addMonths,
  subMonths,
  // startOfMonth,
  // lastDayOfMonth,
} from "date-fns";
import { fetchClientes } from "@/api/gestor/clientes.js";
export default {
  name: "ListaAniversarios",

  props: {
    cliente_id: {
      type: [Number, String],
    },
    clienteComponent: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    // aniversariosNovoTrabalho: () =>
    //   import("./components/aniversariosNovoTrabalho.vue"),
    // aniversariosEditarTrabalho: () => import("./aniversariosEditarTrabalho.vue"),
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      searchCliente: "",
      selectedCliente: null,
      loadingCliente: false,
      listaAniversarios: [],
      dataCalendario: format(new Date(), "yyyy-MM"),
      edit: false,
      aniversarioSelected: null,
      pdfData: null,
      pdfDialog: false,
      clientes: [],
      menu: false,
      mesAtual: format(new Date(), "yyyy-MM"),
      expanded: [],
    };
  },
  watch: {
    dataCalendario() {
      if (this.dataCalendario) {
        this.buscaAniversario(this.selectedCliente, this.dataCalendario + "-01");
      }
    },

    selectedStatus() {
      if (this.selectedStatus) {
        this.buscaAniversario(this.selectedCliente, this.dataCalendario + "-01");
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Funcionário",
          width: "",
          value: "nome",
        },
        {
          text: "Empresa",
          width: "",
          value: "nome_fantasia",
        },
        {
          text: "Telefone",
          width: "",
          value: "telefone",
        },
        {
          text: "Aniversário",
          width: "",
          value: "data_aniversario",
        },
      ];
    },
    mostraMes() {
      let date = this.dataCalendario + "01";
      return format(parseISO(date), "MMM");
    },
  },

  methods: {
    addMonth() {
      this.dataCalendario = format(
        addMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    subMonth() {
      this.dataCalendario = format(
        subMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    buscaAniversario() {
      this.getAniversarios(
        `?data=${this.dataCalendario + "-01"}&cliente_id=${
          this.selectedCliente ? this.selectedCliente.id : ""
        }`
      );
    },
    getClientes() {
      this.loadingCliente = true;
      fetchClientes()
        .then((response) => {
          this.clientes = response;
          this.loadingCliente = false;
        })
        .catch(() => {
          this.loadingCliente = false;
        });
    },
    editaniversario(item) {
      this.aniversarioSelected = { ...item };
      this.dialogEditaniversario = true;
    },
    zeroPad(num) {
      let str = String(num);
      if (str.length < 2) {
        return "0" + str;
      }

      return str;
    },

    async getAniversarios(filtro) {
      this.loading = true;
      return await fetchAniversariosEquipeClientes(filtro ? filtro : "")
        .then((response) => {
          this.listaAniversarios = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pdfContent() {
      let content = [];
      let count = 0;

      let objeto_cabecario = {
        margin: [0, 0, 0, 10],
        fontSize: 8,
        columns: [
          {
            text: "OLE DESIGNER & COMUNICAÇÃO",
            alignment: "left",
          },
          {
            text:
              "CRUZ E BETTO PRESTADORA DE SERVICOS EM DESIGN LTDA | CNPJ: 08.004.795/0001-18 | Telefone" +
              "(45) 3025-7263 | Endereço Rua Engenheiro Rebouças, 434 | Bairro: Centro | CEP: 85851-190 | Cidade: Foz" +
              "do Iguaçu | Estado: PR",
            alignment: "center",
          },
          {
            text: "ANIVERSÁRIOS EQUIPES DE CLIENTES",
            fontSize: 16,
            bold: true,
            alignment: "center",
          },
        ],
      };
      content.splice(count, 0, objeto_cabecario);
      count += 1;

      let tabela_corpo = [];
      let tabela_header = [
        {
          text: "Funcionário",
          bold: true,
          alignment: "center",
          fontSize: 8,
        },
        {
          text: "Empresa",
          bold: true,
          alignment: "center",
          fontSize: 8,
        },
        {
          text: "Telefone",
          bold: true,
          alignment: "center",
          fontSize: 8,
        },
        {
          text: "Aniversário",
          bold: true,
          alignment: "center",
          fontSize: 8,
        },
      ];

      tabela_corpo.push(tabela_header);

      this.listaAniversarios.forEach((colaborador) => {
        let body_line = [
          {
            text: colaborador.nome,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: colaborador.nome_fantasia,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: colaborador.telefone,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: format(parseISO(colaborador.data_aniversario), "dd/MM/yyyy"),
            fontSize: 8,
            alignment: "center",
          },
        ];

        tabela_corpo.push(body_line);
      });

      let obj_tempo = {
        margin: [0, 0, 0, 10],
        table: {
          widths: ["*", "*", "*", "*"],
          headerRows: 1,
          body: tabela_corpo,
          dontBreakRows: true,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i <= 1 || i === node.table.body.length ? 1 : 0;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 0 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "#CFD8DC" : "#CFD8DC";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "#CFD8DC" : "#CFD8DC";
          },
          fillColor: function (rowIndex) {
            return rowIndex > 0 && rowIndex % 2 === 0 ? "#E0E0E0" : null;
          },
        },
      };

      content.splice(count, 0, obj_tempo);
      return content;
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.pdfContent()).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },
  async mounted() {
    await this.getClientes();
    await this.getAniversarios(`?data=${this.mesAtual}`);
  },
};
</script>

<style lang="scss"></style>
